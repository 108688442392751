import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/home.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    // 아래처럼 바로 NotFound 경로를 매칭해도 됨
  },
  {
    path: '/404',
    component: () => import('../components/404_error.vue'),
  },
  {
    path: '/greeting',
    name: 'greeting',
    component: () => import('../components/greeting.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../components/news.vue'),
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../components/notice.vue'),
  },
  {
    path: '/news_list',
    name: 'news_list',
    component: () => import('../components/news_list.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/news_detail',
    name: 'news_detail',
    component: () => import('../components/news_detail.vue'),
  },
  {
    path: '/news_write',
    name: 'news_write',
    component: () => import('../components/news_write.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/notice_list',
    name: 'notice_list',
    component: () => import('../components/notice_list.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/notice_detail',
    name: 'notice_detail',
    component: () => import('../components/notice_detail.vue'),
  },
  {
    path: '/notice_write',
    name: 'notice_write',
    component: () => import('../components/notice_write.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/news_edit',
    name: 'news_edit',
    component: () => import('../components/news_edit.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/notice_edit',
    name: 'notice_edit',
    component: () => import('../components/notice_edit.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/admin_login',
    name: 'admin_login',
    component: () => import('../components/admin_login.vue'),
  },
  {
    path: '/home_news',
    name: 'home_news',
    component: () => import('../components/home_news.vue'),
  },
  {
    path: '/home_notice',
    name: 'home_notice',
    component: () => import('../components/home_notice.vue'),
  },
  {
    path: '/move',
    name: 'move',
    component: () => import('../components/move.vue'),
  },
  {
    path: '/salvation',
    name: 'salvation',
    component: () => import('../components/salvation.vue'),
  },
  {
    path: '/hospital',
    name: 'hospital',
    component: () => import('../components/hospital.vue'),
  },
  {
    path: '/edu',
    name: 'edu',
    component: () => import('../components/edu.vue'),
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../components/history.vue'),
  },
  {
    path: '/doctors',
    name: 'doctors',
    component: () => import('../components/doctors.vue'),
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.afterEach(() => {
  document.title = '몽골아가페기독병원';
});



router.beforeEach((to, from, next) => {
  const requiredAuthorization = to.matched.some(
    (record) => record.meta.authorization
  )
  const token = sessionStorage.getItem('jwt_token')
  if (requiredAuthorization && !token) {
    next({ name: 'admin_login' })
  } else {
    // 그 외의 경우에는 그냥 next 함수를 호출하여 다음 페이지로 이동합니다.
    next()
  }
})



export default router
