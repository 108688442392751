import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import axios from 'axios'
import store from './store/store'

import AOS from 'aos'
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/css/style.css'

const app = createApp(App)

app.config.globalProperties.$axios = axios
app.use(store)
app.use(router)
app.use(AOS)
app.mount('#app')

AOS.init()
